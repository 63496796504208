<!-- eslint-disable max-len -->
<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.6 15.643V8.357L15.835 12 9.6 15.643zm13.898-9.488a3.024 3.024 0 0 0-2.121-2.148C19.505 3.5 12 3.5 12 3.5s-7.505 0-9.377.507A3.024 3.024 0 0 0 .502 6.155C0 8.049 0 12 0 12s0 3.951.502 5.845a3.023 3.023 0 0 0 2.121 2.147C4.495 20.5 12 20.5 12 20.5s7.505 0 9.377-.508a3.023 3.023 0 0 0 2.121-2.147C24 15.951 24 12 24 12s0-3.951-.502-5.845z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>
